
import axios from "axios";
export default {
  sendToServer: async function (method, serviceName, data, errorsToReturn) {
    let config = {
      method: method,
      url:
        "https://a5azwtwmb7.execute-api.eu-west-1.amazonaws.com/DEV/get/" +
        serviceName,
      headers: {
        "x-api-key": "RyQkugSVMs7dUQNQcgHml563xNwOptW49aoM2L46",
        "content-type": "application/json",
      },
      data: JSON.stringify(data),
    };
    let serverResponse = await axios(config).catch((error) => {
      if (error.response) {
        console.log("ErrorCode 1:" + error.response.data);
        if (error.response.data && error.response.data.messages && error.response.data.messages.length > 0) {
          // validation errors from the servers
          error.response.data.messages.forEach(function (item) {
            errorsToReturn.push(item);
          });
        } else {
          errorsToReturn.push(
            "Error occured on the server. Please try again later"
          );
          return error;
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log("ErrorCode 2:" + error.request);
        errorsToReturn.push(
          "Cannot connect to the server. Please try again"
        );
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("ErrorCode 3", error.message);
        errorsToReturn.push(
          "Error on application. Please refresh your page"
        );
      }
    });
    return serverResponse;
  },

  // Tune deltaMin according to your needs. Near 0 it will almost
  // always trigger, with a big value it can never trigger.
  // detectSwipe: function (id, func, deltaMin = 120) {
  //   const swipe_det = {
  //     sX: 0,
  //     sY: 0,
  //     eX: 0,
  //     eY: 0
  //   }
  //   // Directions enumeration
  //   const directions = Object.freeze({
  //     UP: 'up',
  //     DOWN: 'down',
  //     RIGHT: 'right',
  //     LEFT: 'left'
  //   })
  //   let direction = null
  //   const el = document.getElementById(id)
  //   el.addEventListener('touchstart', function (e) {
  //     const t = e.touches[0]
  //     swipe_det.sX = t.screenX
  //     swipe_det.sY = t.screenY
  //   }, false)
  //   el.addEventListener('touchmove', function (e) {
  //     // Prevent default will stop user from scrolling, use with care
  //     // e.preventDefault();
  //     const t = e.touches[0]
  //     swipe_det.eX = t.screenX
  //     swipe_det.eY = t.screenY
  //   }, false)
  //    /* eslint-disable no-unused-vars */
  //   el.addEventListener('touchend', function (e) {
  //     const deltaX = swipe_det.eX - swipe_det.sX
  //     const deltaY = swipe_det.eY - swipe_det.sY
  //     // Min swipe distance, you could use absolute value rather
  //     // than square. It just felt better for personnal use
  //     if (deltaX ** 2 + deltaY ** 2 < deltaMin ** 2) return
  //     // horizontal
  //     if (deltaY === 0 || Math.abs(deltaX / deltaY) > 1)
  //       direction = deltaX > 0 ? directions.RIGHT : directions.LEFT
  //     else // vertical
  //       direction = deltaY > 0 ? directions.UP : directions.DOWN

  //     if (direction && typeof func === 'function') func(el, direction)

  //     direction = null
  //   }, false)
  // }

}