<template>
  <div class="container">
    <div class="line">
      <ul v-if="errors && errors.length > 0">
        <li v-for="error in errors" class="error" v-bind:key="error">{{ error }}</li>
      </ul>
      <LoadingWidget v-bind:loadWidget="showLoading" />
      <slot></slot>
    </div>
  </div>
</template>

<script>
import LoadingWidget from "@/components/LoadingWidget.vue";
export default {
  name: "Border",
  components: {
    LoadingWidget,
  },
  props: {
    showLoading: Boolean,
    errors: Array,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.line {
  background-color: rgba(251, 251, 251, 0.7);
  padding: 5px;
  text-align: left;
  border-radius: 6px;
  margin: 5px;
  /* max-width: 600px; */
  max-width: 720px;
  width: 100%;
}
ul {
  list-style-type: none;
  padding: 5px;
  margin: 0px;
  /* color: red; */
}
li {
  padding: 0px;
  margin: 0px;
}
.error {
  font-weight: bold;
  color: red;
}
</style>
