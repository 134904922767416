<template>
  <div class="loading" v-bind:class="{ show: showLoadScreen }">
    <div class="loader"></div>
    <!-- <img src="../../public/img/catLoader.gif" class="animateLoader" /> -->
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: {
    showLoadScreen: Boolean,
  },
  data: function () {
    return {
    };
  },
  watch: {
    showLoadScreen() {
      // if (this.showLoadScreen) {
      //   setTimeout(function () {}, 10000);
      // }
    },
  },
  mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.animateLoader {
  width: 300px;
  height: auto;
  border-radius: 20%;
  opacity: 0.9;
  padding: 5px;
}
.loading {
  display: none;
  top: 0px;
  left: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(128, 128, 128, 0.6);
  /* border: 1px solid red; */
  z-index: 9999
}

.loading.show {
  display: flex;
}
.loader {
  border: 10px solid grey;
  border-radius: 90%;
  border-top: 10px solid rgba(214, 212, 212, 0.7);
  border-bottom: 10px solid rgba(214, 212, 212, 0.7);
  width: 30px;
  height: 30px;
  position: fixed;
  top: 5px;
  left: 5px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
