<template>
  <div class="loadingWidget" v-bind:class="{ show: loadWidget }">
    <div class="loader"></div>
  </div>
</template>

<script>
export default {
  name: "LoadingWidget",
  props: {
    loadWidget: Boolean,
  },
  watch: {
    loadWidget() {
    },
  },
  mounted: function () {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.loadingWidget {
  display: none;
  position: absolute;
  z-index: 9999
}

.loadingWidget.show {
  display: flex;
}

.loader {
  border: 5px solid blue;
  border-radius: 90%;
  border-top: 5px solid rgba(133, 129, 129, 0.7); 
  border-bottom: 5px solid rgba(133, 129, 129, 0.7);
  width: 5px;
  height: 5px;
  position: absolute;
  top: 3px;
  left: 3px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
